import store from '@/store'

/**
 * 微应用聚合
 * name: 微应用名称，具有唯一性
 * entry: 微应用入口，通过该地址加载微应用
 * container: 微应用挂载节点，微应用加载完成后将挂载在该节点上
 * activeRule: 微应用触发的路由规则，触发路由规则后将加载该微应用
 */
const apps = [
	// 工作台 - 30002
	{
		name: 'WorkbenchApp',
		entry: '//' + window.location.host + '/workbench',
		container: '#app',
		activeRule: '/smartsite/workbench',
		props: {
			store
		}
	},
	// 可视化大屏 - 30003
	{
		name: 'BigscreenApp',
		entry: '//' + window.location.host + '/bigscreen',
		container: '#app',
		activeRule: '/smartsite/bigscreen',
		props: {
			store
		}
	},
	// 基础模块 - 30004
	{
		name: 'BasicApp',
		entry: '//' + window.location.host + '/basic',
		container: '#app',
		activeRule: '/smartsite/basic',
		props: {
			store
		}
	},
	// 公共库 - 30005
	{
		name: 'LibraryApp',
		entry: '//' + window.location.host + '/library',
		container: '#app',
		activeRule: '/smartsite/library',
		props: {
			store
		}
	},
	// 数字化工地系统 - 30006
	{
		name: 'WorkSiteApp',
		entry: '//' + window.location.host + '/worksite',
		container: '#app',
		activeRule: '/smartsite/worksite',
		props: {
			store
		}
	},
	// 质量监督管理系统 - 30007
	{
		name: 'QualityApp',
		entry: '//' + window.location.host + '/quality',
		container: '#app',
		activeRule: '/smartsite/quality',
		props: {
			store
		}
	},
	// 安全生产管理系统 - 30008
	{
		name: 'SafetyApp',
		entry: '//' + window.location.host + '/safety',
		container: '#app',
		activeRule: '/smartsite/safety',
		props: {
			store
		}
	},
	// 计划进度管理系统 - 30009
	{
		name: 'ProgressApp',
		entry: '//' + window.location.host + '/progress',
		container: '#app',
		activeRule: '/smartsite/progress',
		props: {
			store
		}
	},
	// 综合履职管理系统 - 30010
	// 工程大数据决策平台 - 30011
	// 数字化工程监管平台 - 30012
	// 综合数字化运营平台 - 30013
	// 客户权限管理平台 - 30014
	{
		name: 'AuthApp',
		entry: '//' + window.location.host + '/auth',
		container: '#app',
		activeRule: '/smartsite/auth'
	},
	// IOT - 30015
	{
		name: 'IOTApp',
		entry: '//' + window.location.host + '/iot',
		container: '#app',
		activeRule: '/smartsite/iot'
	},
	// H5 - 30016
	{
		name: 'H5App',
		entry: '//' + window.location.host + '/h5',
		container: '#app',
		activeRule: '/smartsite/h5'
	},
	// 工程档案 - 30017
	{
		name: 'archiveApp',
		entry: '//' + window.location.host + '/archive',
		container: '#app',
		activeRule: '/smartsite/archive'
	},
	// 工程BI大屏 - 30018
    {
		name: 'MaintenanceSupervisionApp',
		entry: '//' + window.location.host + '/maintenance-supervision',
		container: '#app',
		activeRule: '/smartsite/maintenance-supervision'
	},
	// 养护管理 - 30020
	{
		name: 'maintainApp',
		entry: '//' + window.location.host + '/maintain',
		container: '#app',
		activeRule: '/smartsite/maintain'
	},
	// 项目前期 - 30021
	{
		name: 'prophaseApp',
		entry: '//' + window.location.host + '/prophase',
		container: '#app',
		activeRule: '/smartsite/prophase'
	},
	// 项目前期 - 30022
	{
		name: 'personnelApp',
		entry: '//' + window.location.host + '/personnel',
		container: '#app',
		activeRule: '/smartsite/personnel'
	},
	// 项目前期 - 30023
	{
		name: 'carApp',
		entry: '//' + window.location.host + '/car',
		container: '#app',
		activeRule: '/smartsite/car'
	},
	// 项目前期 - 30024
	{
		name: 'inspectionApp',
		entry: '//' + window.location.host + '/inspection',
		container: '#app',
		activeRule: '/smartsite/inspection'
	},
	// 邮件 - 30025
	{
		name: 'mailApp',
		entry: '//' + window.location.host + '/mail',
		container: '#app',
		activeRule: '/smartsite/mail'
	},
	// 机具管理 - 30026
	{
		name: 'implementApp',
		entry: '//' + window.location.host + '/Implement',
		container: '#app',
		activeRule: '/smartsite/Implement'
	},
	// 项目前期 - 30027
	{
		name: 'QrcodemanageApp',
		entry: '//' + window.location.host + '/qrcodemanage',
		container: '#app',
		activeRule: '/smartsite/qrcodemanage'
	},
	// 项目前期 - 30028
	{
		name: 'QqbigscreenApp',
		entry: '//' + window.location.host + '/qqbigscreen',
		container: '#app',
		activeRule: '/smartsite/qqbigscreen'
	}


]

export default apps
